import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "date": "2018-06-06T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "https://www.deviantart.com/artofjokinen/art/ARTEMIS-723163712"
      }}><img parentName="a" {...{
          "src": "/reading/artemis-illustration.jpg",
          "alt": "Artemis illustration by artofjokinen"
        }}></img></a></p>
    <ul>
      <li parentName="ul">{`Title: `}<strong parentName="li">{`Artemis`}</strong></li>
      <li parentName="ul">{`Andy Weir`}</li>
    </ul>
    <p>{`My rating: ★★`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      